import * as React from "react";
import { connect } from "react-redux";
import { loadTranslations, setLocale } from "react-redux-i18n";
import { Route } from "react-router";
import { BrowserRouter, Switch } from "react-router-dom";
import * as _ from "lodash";
import Layout from "./components/Layout";
import PageAbout from "./components/page/PageAbout";
import PageArticle from "./components/page/PageArticle";
import PageArticles from "./components/page/PageArticles";
import PageArticlesList from "./components/page/PageArticlesList";
import PageCategory from "./components/page/PageCategory";
import PageDataset from "./components/page/PageDataset";
import PageHome from "./components/page/PageHome";
import Throbber from "./components/Throbber";
import { scwApi } from "./services/Api";
import { LanguageService } from "./services/LanguageService";
import { LanguageDTO } from "./services/models/LanguageDTO";

import "./App.css";


type AppProps = {
    dataLanguage: any,
    dataCategory: any,
    loadTranslations: Function,
    locale: string[2],
    scwApiLanguageGet: Function,
    scwApiCategoriesGetHierarchyAll: Function,
    setLocale: Function,
}

type AppState = {
    isLoaded: boolean,
}


class App extends React.Component<AppProps, AppState> {

    constructor(props: Readonly<AppProps>) {
        super(props);

        this.state = {
            isLoaded: false
        };

        const lsLang = localStorage.getItem("language");
        if (lsLang === LanguageService.ENGLISH || lsLang === LanguageService.CYMRAEG) {
            this.props.setLocale(lsLang);
        } else {
            this.props.setLocale(LanguageService.ENGLISH);

            localStorage.setItem("language", LanguageService.ENGLISH);
        }

        
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<AppProps>, prevState: AppState): Partial<AppState> | null {

        const lbIsLoaded = (
            !_.isEmpty(nextProps.locale)
            && !_.isNil(nextProps.dataCategory) && (nextProps.dataCategory.isSuccess === true)
            && !_.isNil(nextProps.dataLanguage) && (nextProps.dataLanguage.isSuccess === true)
        );

        return {
            isLoaded: lbIsLoaded
        };
    } // getDerivedStateFromProps

    reloadTranslations(data: LanguageDTO[]) {
        if (_.isNil(data) || _.isEmpty(data))
            return;

        const loTranslationsObject: any = {};

        loTranslationsObject[(data[0] as LanguageDTO).code] = {};

        _.map(
            data,
            (languageItem: LanguageDTO) => {
                loTranslationsObject[languageItem.code][languageItem.key] = languageItem.value;
            }
        );

        this.props.loadTranslations(loTranslationsObject);
    } // reloadTranslations

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="throbber-center">
                    <Throbber />
                </div>
            );
        }

        if (this.props.dataCategory.isError || this.props.dataLanguage.isError)
            return (
                <div className="throbber-center">
                    Error
                </div>
            );

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' render={(props) => (<Layout {...props}><PageHome {...props} /></Layout>)} />
                    <Route exact path='/article' render={(props) => (<Layout {...props}><PageArticle {...props} /></Layout>)} />
                    <Route exact path='/articles' render={(props) => (<Layout {...props}><PageArticles {...props} /></Layout>)} />
                    <Route exact path='/articles-list' render={(props) => (<Layout {...props}><PageArticlesList {...props} /></Layout>)} />
                    <Route exact path='/category' render={(props) => (<Layout {...props}><PageCategory {...props} /></Layout>)} />
                    <Route exact path='/dataset' render={(props) => (<Layout {...props}><PageDataset {...{ ...props, visualOnlyPath: undefined }} /></Layout>)} />
                    <Route exact path='/visual' render={(props) => (<PageDataset {...{ ...props, visualOnlyPath: "/visual" }} />)} />
                    <Route path='/about' render={(props) => (<Layout {...props}><PageAbout {...props} /></Layout>)} />
                </Switch>
                { /* <Redirect to='/' /> */ }
            </BrowserRouter>
        );
    } // render

    shouldComponentUpdate(nextProps: Readonly<AppProps>, nextState: Readonly<any>, nextContext: any): boolean {

        // If language has changed, re-get categories
        if (this.props.locale !== nextProps.locale) {
            this.props.scwApiLanguageGet(nextProps.locale);
            this.props.scwApiCategoriesGetHierarchyAll(nextProps.locale);

            if (!_.isNil(nextProps.dataLanguage.data))
                this.reloadTranslations(nextProps.dataLanguage.data);
        }

        const isLanguageDataEqual = _.isEqual(this.props.dataLanguage.data, nextProps.dataLanguage.data);
        if (!isLanguageDataEqual && !_.isNil(nextProps.dataLanguage.data))
            this.reloadTranslations(nextProps.dataLanguage.data);

        return (
            (this.props.locale !== nextProps.locale)
             || (!isLanguageDataEqual)
             || (!_.isEqual(this.props.dataCategory.data, nextProps.dataCategory.data))
        );
    } // shouldComponentUpdate

}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataLanguage: scwApi.endpoints.languageGet.select(state.i18n.locale)(state),
            dataCategory: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state)
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            loadTranslations: (translationsObject: any) => dispatch(loadTranslations(translationsObject)),
            scwApiLanguageGet: (languageCode: string) => dispatch(scwApi.endpoints.languageGet.initiate(languageCode)),
            scwApiCategoriesGetHierarchyAll: (languageCode: string) => dispatch(scwApi.endpoints.categoriesGetHierarchyAll.initiate(languageCode)),
            setLocale: (locale: string) => dispatch(setLocale(locale))
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(App);