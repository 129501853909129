import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { scwApi } from "../../services/Api";
import { ArticleDTO, ArticleContentBlockDTO } from "../../services/models/ArticleDTO";
import "./PageArticle.css";


type PageArticleProps = RouteComponentProps<{}> & {
    dataArticleGet: any,
    locale: string[2],
    scwApiArticleGet: Function,
}

type PageArticleState = {
    article: ArticleDTO | undefined,
    articleContentBlocks: ArticleContentBlockDTO[],
    articlePageContents: any[],
    locale: string[2],
    locationSearch: string,
}

class PageArticle extends React.Component<PageArticleProps, PageArticleState> {

    observer: IntersectionObserver | null = null;

    constructor(props: Readonly<PageArticleProps>) {
        super(props);

        this.state = {
            article: undefined,
            articleContentBlocks: [],
            articlePageContents: [],
            locale: props.locale,
            locationSearch: "",
        };        
    } // constructor

    interceptAndChangeUrlToPortalPowerBiDatasetToVisual(asUrl: string) {
        const url = new URL(asUrl);
        if (url.pathname === "/dataset")
            asUrl = asUrl.replace("/dataset", "/visual");

        return asUrl;
    } // alterPowerBiVisualUrl

    componentDidMount() {
        setTimeout(() => {
            this.observer = new IntersectionObserver(
                entries => {
                    entries.forEach(
                        entry => {
                            const id = entry.target.getAttribute('id');

                            if (entry.intersectionRatio > 0) {
                                document?.querySelector(`nav li a[href$="#${id}"]`)?.parentElement?.classList?.add('active');
                            } else {
                                document?.querySelector(`nav li a[href$="#${id}"]`)?.parentElement?.classList?.remove('active');
                            }
                        }
                    );
                }
            );

            // Track all sections that have an `id` applied
            document.querySelectorAll('section[id]').forEach((section) => {
                this.observer?.observe(section);
            });
        }, 3000);
    }

    componentWillUnmount() {
        document.querySelectorAll('section[id]').forEach((section) => {
            this.observer?.unobserve(section);
        });
    } // componentWillUnmount

    static getDerivedStateFromProps(nextProps: Readonly<PageArticleProps>,
        prevState: PageArticleState): Partial<PageArticleState> | null {

        const loParams = new URLSearchParams(nextProps.location.search);

        const lsArticleId = loParams.get("a");

        const loArticleResponse = nextProps.dataArticleGet(nextProps.locale, lsArticleId);
        let loArticle: ArticleDTO | undefined = undefined;

        if (loArticleResponse.isSuccess) {
            loArticle = (JSON.parse(loArticleResponse.data) as ArticleDTO);
        } else if (!loArticleResponse.isLoading && !loArticleResponse.isError) {
            nextProps.scwApiArticleGet(nextProps.locale, lsArticleId);
        }

        const loArticleContentBlocks = loArticle?.contentBlocks.sort((a, b) => a.sortOrder ?? 0 - b.sortOrder ?? 0);

        const loArticlePageContents: any[] = [];
/*
        if (!_.isNil(loArticleContentBlocks)) {
            for (var i = 0; i < loArticleContentBlocks.length; i++) {
                const loArticlePageContents = loArticleContentBlocks?.filter(contentBlock => !!contentBlock.enabled && contentBlock.showSectionHeader === "yes");
            }
        }
*/

        return {
            article: loArticle,
            articleContentBlocks: loArticleContentBlocks,
            articlePageContents: loArticlePageContents,
            locale: nextProps.locale,
            locationSearch: nextProps.location.search,
        };
    } // getDerivedStateFromProps

    render() {
        if (!this.state.article)
            return null;

        return (
            <main className="page-article">
                <header className="page-article-header">
                    <h1>{this.state.article.title}</h1>
                    <h2>{this.state.article.intro}</h2>
                    <div className="page-article-header-metadata">
                        <section className="page-article-header-metadata-item">
                            <h1>
                                {I18n.t("frontend_article_Contact")}
                            </h1>
                            <span>
                                {`${this.state.article.author.firstName} ${this.state.article.author.lastName}`}
                            </span>
                        </section>
                        <section className="page-article-header-metadata-item">
                            <h1>
                                {I18n.t("frontend_article_Publication")}
                            </h1>
                            <span>
                                {`${(new Date(Date.parse(this.state.article.postDate)))?.toLocaleDateString(`${this.props.locale}-GB`)}`}
                            </span>
                        </section>
                        
                        <section className="page-article-header-metadata-item">
                            { /*
                            <h1>
                                Release
                            </h1>
                            <a></a>
                            */ }
                        </section>
                        <section className="page-article-header-metadata-item">
                            { /*
                            <h1>
                                {I18n.t("frontend_article_Category")}
                            </h1>
                            <a></a>
                            */ }
                        </section>
                    </div>
                </header>
                <div className="page-article-body">
                    <aside className="page-article-toc">
                        <nav>
                            <section>
                                <header>
                                    <h1>{I18n.t("frontend_article_PageContents")}</h1>
                                </header>
                                <ul>
                                    {
                                        this.state.article.contentBlocks.map(
                                            contentBlock => {
                                                if (!contentBlock.enabled)
                                                    return null;

                                                if (contentBlock.showSectionHeader !== "yes")
                                                    return null;

                                                return (
                                                    <li key={contentBlock.id}>
                                                        <a className="page-article-toc-link" href={this.props.location.pathname + this.props.location.search + "#articleBlock" + contentBlock.id}>{contentBlock.sectionHeader}</a>
                                                    </li>
                                                );
                                            }
                                        )
                                    }
                                </ul>
                            </section>
                        </nav>
                    </aside>
                    <div className="page-article-main">
                        <section className="page-article-section page-article-summary">
                            <h3>{I18n.t("frontend_article_Summary")}</h3>
                            <div dangerouslySetInnerHTML={{ __html: this.state.article.summary }} />
                        </section>
                        {
                            this.state.articleContentBlocks.map(
                                contentBlock => {
                                    if (!contentBlock.enabled)
                                        return null;

                                    switch (contentBlock.typeHandle) {
                                        case "powerBi":
                                            return (
                                                <section className="page-article-section page-article-powerBIReport" key={contentBlock.id} id={"articleBlock" + contentBlock.id}>
                                                    <iframe src={this.interceptAndChangeUrlToPortalPowerBiDatasetToVisual(contentBlock.powerBiSource)} frameBorder="0" scrolling="no" />
                                                    <figure></figure>
                                                </section>
                                            );

                                        case "image":
                                            return (
                                                <section className="page-article-section page-article-image" key={contentBlock.id} id={"articleBlock" + contentBlock.id}>
                                                    <h3>{contentBlock.caption}</h3>
                                                    <img src={"https://scwcraftdataportal.azurewebsites.net/assets/images/" + contentBlock.image[0].filename} alt={contentBlock.altText} />
                                                </section>
                                            );;

                                        case "keyFacts":
                                            return (
                                                <section className="page-article-section page-article-keyfacts" key={contentBlock.id} id={"articleBlock" + contentBlock.id}>
                                                    <h3>{I18n.t("frontend_article_KeyFacts")}</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: contentBlock.keyFacts }} />
                                                </section>
                                            );

                                        case "redactor":
                                            return (
                                                <section className="page-article-section" key={contentBlock.id} id={"articleBlock" + contentBlock.id}>
                                                    {(contentBlock.showSectionHeader === "yes") && (<h3>{contentBlock.sectionHeader}</h3>)}

                                                    <div dangerouslySetInnerHTML={{ __html: contentBlock.redactor }} />
                                                </section>
                                            );

                                        default:
                                            return null;
                                    }
                                }
                            )
                        }
                        <section className="page-article-section page-article-contact">
                            <h3>{I18n.t("frontend_article_Contact")}</h3>
                            <div dangerouslySetInnerHTML={{ __html: this.state.article.contact }} />
                        </section>
                    </div>
                </div>
                <footer className="page-article-footer">
                    
                </footer>
            </main>
        );
    } // render
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataArticleGet: (languageCode: string, id: string) => scwApi.endpoints.article.select({ "languageCode": languageCode, "id": id })(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiArticleGet: (languageCode: string, id: string) => dispatch(scwApi.endpoints.article.initiate({ "languageCode": languageCode, "id": id })),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageArticle));